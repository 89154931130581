import * as React from "react";
import { MainLayout } from "../layouts/MainLayout";
import img from "../static/slider_images/2.jpeg";
import a1 from "../images/security/LaseTVM-3D-M1.jpg";
import a2 from "../images/security/Sliderbild_1.jpg";
import a3 from "../images/security/LaseTVM-3D-M2.jpg";
import a4 from "../images/security/Sliderbild_3.jpg";
import a5 from "../images/security/leveling.jpeg";
import a6 from "../images/security/crushing.jpeg";

// markup
const SecuritySystems = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>SECURITY SYSTEMS</h1>
                  {/* <figure className="image is-96x96">
                    <img src={img} alt="Factory" />
                  </figure> */}
                </div>
                <p className="page-description">
                  We offer specific and high-precision devices, which deliver
                  accuracy in volumetric measurements, to optimise movement of
                  manufacturing machinery and equipment.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
                <section className="procedures">
                  {/* <h3 className="title">Products</h3> */}
                  <h5>An example of our security system in work</h5>
                  <ul>
                    <li>
                      <h3>
                        LaseTVM-3D-M For Truck Volume Measurement (Motion)
                      </h3>
                      <p>
                        The application LaseTVM-3D-M (MOTION) is an accurate
                        three-dimensional laser measurement system for the
                        automated and dynamic measurement of truck load volumes
                        on the fly without stopping. The application has a high
                        versatility and can be used for volume and profile
                        measurements of all different materials such as stones,
                        sand, ore or even wood materials – also in rough
                        environments. Two 2D laser scanners from the LASE
                        2000D-11x Series are used in combination with the
                        LaseTVM-3D-M software application.
                      </p>
                      <section>
                        <figure>
                          <img src={a1} alt="scanner" />
                          <p>
                            The two 2D-laser scanners are mounted on the
                            measuring frame
                          </p>
                        </figure>
                        <figure>
                          <img src={a2} alt="scanner" />
                          <p>
                            The scan plane measuring truck payload before exit
                          </p>
                        </figure>
                      </section>
                    </li>
                    <li>
                      <h3>
                        LaseTVM-3D-S For Truck Volume Measurement (Static)
                      </h3>
                      <p>
                        The application LaseTVM-3D-S – Truck Volume Measurement
                        Static is a highly precise three-dimensional measurement
                        system especially developed for the measurement of truck
                        loading volumes. 3D laser scanners from the LASE
                        3000D-C2-11x Series are used in combination with the
                        LaseTVM-Software application.
                      </p>
                      <section>
                        <figure>
                          <img src={a4} alt="scanner" />
                          <p>3D-laser scanner mounted on measuring frame.</p>
                        </figure>
                        <figure>
                          <img src={a3} alt="scanner" />
                          <p>
                            The 3D-scan plane measuring truck payload before
                            exit.
                          </p>
                        </figure>
                      </section>
                    </li>
                    <li>
                      <h3>Mining Equipment Supplier – AVT Sarl</h3>
                      <section>
                        <figure>
                          <img src={a5} alt="scanner" />
                          {/* <p>Mining Equipment Supplier – AVT Sarl</p> */}
                        </figure>
                        <figure>
                          <img src={a6} alt="scanner" />
                          {/* <p>Mining Equipment Supplier – AVT Sarl</p> */}
                        </figure>
                      </section>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Products</h3>
                  <ul>
                    <li>
                      <p className="list-title">
                        <span>Conveyor Volume Measurement systems</span>
                        High-precision Laser based volume measurement systems,
                        3-D truck volume measurements which are used in conveyor
                        belts , trucks, silos etc.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">LASE</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Tyre Cages </span>
                        We provide different sizes and models of tyre cages.
                        Individually adaptations are possible, including
                        colours. The distinct features include explosion-proof
                        technology.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">AVT</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Fence Controllers</span>
                        The fence controller systems are suitably designed,
                        providing consistent performance and scalability to meet
                        the specific requirements.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">LASE</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Motion Analysis Equipment</span>
                        This technology is based on high-precision Laser
                        measurements, using 2D and 3 D Laser systems. The
                        technology is software powered, based on determinations
                        of pixels, movement and 3D for volume measurements.
                        Special sensors are used as hardware to detect the
                        motion, using locally installed Laser scanners.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">LASE</span>
                    </li>
                  </ul>
                </section>
                {/* <p className="services-info">
                  This technology is based on high-precision Laser measurements,
                  using 2D and 3 D Laser systems. The technology is software
                  powered, based on determinations of pixels, movement and 3D
                  for volume measurements. Special sensors are used as hardware
                  to detect the motion, using locally installed Laser scanners.
                </p> */}
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default SecuritySystems;
