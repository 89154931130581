import React from "react";
import { Link } from "gatsby";
import marker from "../images/map-marker.png";
import phone from "../images/phone.png";
import mail from "../images/mail.png";
import * as style from "./Footer.module.scss";

export const Footer = () => {
  return (
    <footer className={style.footer_container}>
      <section className="footer_menu">
        {/* <header>
          <h3 className="company_title is-size-5">avt sarl</h3>
        </header> */}
        <main className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h3 className="is-size-5">Main Menu</h3>
                <ul className="main_menu">
                  <li>
                    <Link activeClassName="active" to="/">
                      Home Page
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/industrial-equipment">
                      Industrial Equipment
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/security-systems">
                      Security Systems
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/suspension">
                      Suspension Systems
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClassName="active"
                      to="/design-technology-consultancy"
                    >
                      Design Consultancy
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/driveline-parts">
                      Driveline Parts
                    </Link>
                  </li>
                  <li>
                    <Link activeClassName="active" to="/about">
                      AVT Management Team
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="column">
                <h3 className="is-size-5">Quick Links</h3>
                <ul className="quick_links">
                  <li>
                    <Link to="/industrial-equipment">Mining Equipment </Link>
                  </li>
                  <li>
                    <Link to="/security-systems">
                      Volume Measurement Systems{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/driveline-parts">Driveline Parts - Trucks </Link>
                  </li>
                  <li>
                    <Link to="/design-technology-consultancy">
                      Technology Consultancy{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/driveline-parts">Driveline Parts - Mining </Link>
                  </li>
                  <li>
                    <Link to="/design-technology-consultancy">
                      Reproduction - Parts{" "}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="column">
                <h3 className="is-size-5">For Enquiries, Please Contact</h3>
                <ul className="contact">
                  <li>
                    <img src={marker} alt="location" />
                    <p>
                      <a target="_blank" href="https://www.google.com/maps/place/8+All.+du+Canal,+42160+Saint-Cyprien,+France/@45.5427589,4.2281811,17z/data=!3m1!4b1!4m5!3m4!1s0x47f44b686afc7a01:0x2e41ad1545a974d4!8m2!3d45.5427589!4d4.2303698">

                      Head Office : 8, Allee du Canal, F 42160 St Cyprien,
                      France
                      </a>
                    </p>
                  </li>
                  <li>
                    <img src={mail} alt="location" />
                    <p className="is-flex is-flex-direction-column">
                      <a href="mailto:avt@avt.sarl" className="is-size-6"><strong>avt@avt.sarl</strong></a>
                      {/* <a href="tel:+33 9 80 95 81 80">+33 9 80 95 81 80</a>
                      <a href="tel:+33 60 40 70 601">+33 60 40 70 601</a> */}
                    </p>
                  </li>
                  <li>
                    <img src={phone} alt="location" />
                    <section>
                      <p>
                        Sales :
                        <a href="tel:+33 – 9 80 95 81 80">
                          +33 9 80 95 81 80 &nbsp;&nbsp;&nbsp;
                        </a>
                      </p>
                      <p>
                        Logistics :
                        <a href="tel:+33 – 9 80 95 81 80">
                          +33 9 83 44 35 26 &nbsp;&nbsp;&nbsp;
                        </a>
                      </p>
                      {/* <p>
                        Sales :
                        <a href="tel:+33 – 9 80 95 81 80">
                          +33 9 80 95 81 80, +33 60 40 70 601 &nbsp;&nbsp;&nbsp;
                        </a>
                      </p>
                      <p>
                        Invoice :
                        <a href="tel:+33 – 9 80 95 81 80">
                          +33 9 80 95 81 80 &nbsp;&nbsp;&nbsp;
                        </a>
                      </p>
                      <p>
                        Accounting :
                        <a href="tel:+33 – 9 80 95 81 80">
                          +33 7 77 06 27 13&nbsp;&nbsp;&nbsp;
                        </a>
                      </p> */}
                    </section>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </section>
      <section className="footer">
        <div className="content has-text-centered">
          <p>
            © Copyright AVT Sarl 2021 - Content :{" "}
            <a href="http://bygeorge.in" target="_blank">
              ByGeorge.in
            </a>{" "}
            | Design:
            <a href="http://www.23designs.xyz" target="_blank">
              {" "}
              23Design.xyz with Helena Moser
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
};
