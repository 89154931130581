import React from 'react';
import {Header} from '../components/Header';
import {Footer} from "../components/Footer";
import '../styles/style.scss';

export const MainLayout = (props) => {
    return (
        <div className='home-container'>
            <Header></Header>
            <section className='main-contents'>
                <main>
                    {props.children}
                </main>
                <Footer></Footer>
            </section>
        </div>
    )
}