import React, { useState } from "react";
import { Link } from "gatsby";
// import OutsideClickHandler from "react-outside-click-handler";
// import logo from "../images/logo-128.png";
import logo from "../images/avt_logo.gif";
import * as style from "./Header.module.scss";

export const Header = () => {
  const [menu, setMenu] = useState(false);
  // const [sub_menu, setSubMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };
  // const toggleSubMenu = () => {
  //   setSubMenu(!sub_menu);
  // };
  return (
    <section className={style.header}>
      <p className="contact">
        <span>
          Contact for Enquiry: +33 9 80 95 81 80 |{" "}
          <a href="mailto:avt@avt.sarl">
            <strong>avt@avt.sarl</strong>
          </a>
        </span>
        <span>8, Allee du Canal, F 42160 St Cyprien, France </span>
      </p>
      <nav className="navigation">
        <figure>
          <Link to="/">
            <img src={logo} />
          </Link>
        </figure>
        <a className="mobile-menu-toggle" onClick={toggleMenu}>
          {/* <img src={hamburger} alt="menu-toggle" /> */}
          <div id="nav-icon3" className={menu ? "open" : ""}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        {/* <OutsideClickHandler onOutsideClick={toggleMenu}> */}

        <ul className={menu ? "block" : ""}>
          <li>
            <Link activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/industrial-equipment">
              Industrial
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/driveline-parts">
              Driveline
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/axles-development">
              Axles
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/suspension">
              Suspension
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/security-systems">
              Security
            </Link>
          </li>

          <li>
            <Link activeClassName="active" to="/design-technology-consultancy">
              Consultancy
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
        {/* </OutsideClickHandler> */}
      </nav>
    </section>
  );
};
